import React from 'react'
import {
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import Button from '../components/button'
import { Formik } from 'formik'
import axios from 'axios'
import Layout from '../layout/layout'
import { ContentDiv } from '../theme/html_render'

const ProgramSet = ({
  goToNext,
  markComplete,
  title,
  description,
  user,
  courseContentId,
  currentContent,
}) => {
  return (
    <Layout
      actionButton={
        <Button onClick={goToNext} className="w-auto">
          Continue
        </Button>
      }
    >
      <div className="overflow-y-scroll flex h-screen flex-col justify-between items-end bg-gray-50 p-5">
        <div>
          <h1 className="text-3xl  mb-5 font-medium">{title}</h1>
          {/* <p className="font-normal " style={{ color: '#667085' }}>
            {description}
          </p> */}
            <ContentDiv
              className="px-10 pt-5 pb-15"
              dangerouslySetInnerHTML={{ __html: description }}
            ></ContentDiv>
          <Formik
            initialValues={{
              name: currentContent?.githubLink
                ? currentContent.githubLink.split('/').length > 1
                  ? currentContent.githubLink.split('/')[1]
                  : currentContent.githubLink.split('/')[0]
                : '',
            }}
            onSubmit={(values, { errors, setErrors }) => {
              let saveLink = `${user?.data?.githubUsername}/${values.name}`
              let giturl = `https://api.github.com/repos/${saveLink}`
              axios
                .get(giturl)
                .then((res) => {
                  markComplete(courseContentId, 'SUBMITTED', {
                    githubLink: `${saveLink}`,
                  })
                    .then((res) => {})
                    .catch((err) => {})
                })
                .catch((err) => {
                  setErrors({
                    ...errors,
                    name: 'Please provide a valid repository name',
                  })
                })
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
            }) => (
              <form className="mt-5" noValidate={true} onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label
                    htmlFor="email"
                    className="inline font-normal text-sm mb-2 mr-2"
                    style={{ color: '#000' }}
                  >
                    Github Link
                  </label>
                  <span
                    className={`inline-flex lowercase items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                      currentContent?.status === 'PENDING'
                        ? 'bg-yellow-100 text-yellow-800'
                        : currentContent?.status === 'SUBMITED'
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-green-100 text-green-800'
                    }`}
                  >
                    {currentContent?.status}
                  </span>
                  <div className="relative mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                      https://github.com/{user?.data?.githubUsername}/
                    </span>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      required
                      className="block w-full min-w-0 flex-1 rounded-r-md  px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border"
                      placeholder="repository name"
                    />
                    {touched.name && errors.name && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {touched.name && errors.name && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.name}
                    </p>
                  )}
                </div>
                <Button type="submit" className="mt-4 w-auto float-right">
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="pl-2 pt-4 pb-2 font-medium text-lg ml-2">Test Cases</div>
      <div className="w-full mx-auto pl-3">
        {/* <div
          className="p-2 my-4 flex space-x-2 items-center  border-gray-300 border rounded-md"
          style={{ backgroundColor: '#EFFFF5' }}
        >
          <CheckCircleIcon
            className="h-5 w-5 rounded-2xl"
            style={{ backgroundColor: '#D1FADF', color: '#12B76A' }}
          />
          <span className="flex-1 font-light text-sm">
            Should contain a form
          </span>
        </div>
        <div className="w-100 p-2 my-2 flex space-x-2 items-center bg-red-200 border-gray-300 border rounded-md">
          <ExclamationTriangleIcon className="w-5 text-red-600" />
          <span className="flex-1 font-light text-sm">
            Should contain a form that submits with method post and the
            validation should be diabled
          </span>
        </div> */}
      </div>
    </Layout>
  )
}
export default ProgramSet
