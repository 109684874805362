import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
  CheckCircleIcon,
  CloudArrowUpIcon,
  ArrowUturnLeftIcon,
  PencilIcon,
  CommandLineIcon,
  BookOpenIcon,
  FolderIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline'
import logo from '../../assets/cogoport_logo.svg'
// import ExerciseIcon from '../../assets/icons/exercise_icon'
// import ProblemSetIcon from '../../assets/icons/problem_set'
// import ProjectIcon from '../../assets/icons/project_icon'
// import ReadingMaterialIcon from '../../assets/icons/reading_material'
import EditorLayout from '../../layout/editor/editor_layout'
import ProgramSet from '../../pages/programSet'
import ReadingMaterial from '../../pages/readingMaterial'
import { useNavigate } from 'react-router-dom'

const getIcon = (color) => {
  return {
    EXERCISE: <CommandLineIcon className="w-4 h-4" style={{ color }} />,
    PROBLEM_SET: <FolderIcon className="w-5 h-5" style={{ color }} />,
    PROJECT: <FolderIcon className="w-5 h-5" style={{ color }} />,
    READING_MATERIAL: <BookOpenIcon className="w-5 h-5" style={{ color }} />,
  }
}

const getChildren = (user = null, goToNext, markComplete,markContentComplete, currentContent) => {
  return {
    EXERCISE: (
      <EditorLayout
        goToNext={goToNext}
        markContentComplete={markContentComplete}
        user={user}
        language={currentContent?.courseContent?.codeType}
        courseContentId={currentContent?.courseContent?.id}
        initialCode={
          !!currentContent?.code
            ? currentContent?.code
            : currentContent?.courseContent?.initialCode
        }
        instruction={currentContent?.courseContent?.description}
        testCases={currentContent?.courseContent?.testCases}
      />
    ),
    PROBLEM_SET: (
      <ProgramSet
        goToNext={goToNext}
        markComplete={markComplete}
        user={user}
        courseContentId={currentContent?.courseContent?.id}
        title={currentContent?.courseContent?.title}
        description={currentContent?.courseContent?.description}
        currentContent={currentContent}
      />
    ),
    PROJECT: (
      <ProgramSet
        goToNext={goToNext}
        markComplete={markComplete}
        user={user}
        courseContentId={currentContent?.courseContent?.id}
        title={currentContent?.courseContent?.title}
        description={currentContent?.courseContent?.description}
        currentContent={currentContent}
      />
    ),
    READING_MATERIAL: (
      <ReadingMaterial
        markComplete={markComplete}
        courseContentId={currentContent?.courseContent?.id}
        additionalLinks={currentContent?.courseContent?.additionalLinks || []}
        description={currentContent?.courseContent?.description}
        status={currentContent?.status}
        goToNext={goToNext}
      />
    ),
  }
}

const Sidebar = ({
  categories,
  currentContent,
  setCurrentExercise,
  user,
  goToNext,
  markComplete,
  markContentComplete,
  children,
}) => {
  const navigate = useNavigate()

  const renderCategories = () => {
    return categories.map((c, idx) => {
      return (
        <div
          key={c?.courseContent?.id}
          className={`flex justify-between items-baseline pl-5 pr-2 py-2 rounded-md cursor-pointer hover:bg-sky-100 ${
            c?.courseContent?.id === currentContent?.courseContent?.id &&
            'bg-sky-100'
          }`}
        >
          <span
            className={`
              ${
                c?.courseContent?.id === currentContent?.courseContent?.id
                  ? 'mr-4 mt-1 text-primary'
                  : 'mr-4 mt-1'
              }`}
          >
            <input
              className="w-4 h-4"
              type="checkbox"
              checked={c?.status !== 'PENDING'}
              readOnly
            />
          </span>
          <div
            className={`flex justify-between items-start flex-1 text-sm mb-1
              ${
                c?.courseContent?.id === currentContent?.courseContent?.id
                  ? 'text-primary'
                  : ''
              }
            `}
            onClick={() => {
              setCurrentExercise(c.courseContent)
            }}
          >
            <span>
              {c.courseContent?.title}
              <br></br>
              <span
                className="inline-flex items-center text-gray-400"
                // style={{ fontSize: '12px' }}
              >
                {
                  getIcon(
                    c?.courseContent?.id === currentContent?.courseContent?.id
                      ? '#09678C'
                      : '#344054'
                  )[c.courseContent?.type]
                }
                <span className="pl-1">{c.courseContent?.duration}</span>
              </span>
            </span>
            {/* <span className="ml-1">
              {c?.status === 'PENDING' ? null : c?.status === 'SUBMITTED' ? (
                <CloudArrowUpIcon className="w-5 h-5" />
              ) : (
                <CheckCircleIcon className="w-5 h-5" />
              )}
            </span> */}
          </div>
        </div>
      )
    })
  }

  const goBack = () => {
    navigate('/landing')
  }

  return (
    <>
      <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => {}} //Close Sidebar
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex items-center justify-between flex-shrink-0 px-4">
                  <img className="w-auto h-8" src={logo} alt="Your Company" />
                  <ArrowUturnLeftIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={goBack}
                  />
                </div>
                <div className="flex-1 h-0 mt-5 overflow-y-auto">
                  <h2>Course Content</h2>
                  <nav className="px-2 space-y-1">{renderCategories()}</nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="hidden border-r md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col"
        style={{ width: '19rem' }}
      >
        <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-white">
          <div className="flex items-center justify-between flex-shrink-0 px-4">
            <img className="w-auto h-8 " src={logo} alt="Your Company" />
            <ArrowUturnLeftIcon
              className="w-5 h-5 cursor-pointer"
              onClick={goBack}
            />
          </div>
          <div className="flex flex-col flex-1 mt-5 overflow-y-auto">
            <nav className="h-full px-2 pb-4">
              <h2 className="pb-2 mb-2 ml-3 font-bold border-b border-gray">
                Course Content
              </h2>
              <div>{renderCategories()}</div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 md:pl-32">
        <div className="flex flex-col flex-1 md:pl-44">
          <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow md:hidden">
            <button
              type="button"
              className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black md:hidden"
              onClick={() => {}}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>

          <main>
            {
              getChildren(user, goToNext, markComplete,markContentComplete, currentContent)[
                currentContent?.courseContent?.type
              ]
            }
          </main>
        </div>
        <div></div>
      </div>
    </>
  )
}

export default Sidebar
