import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo2 from '../../assets/icons/logo2.png'
import logo from '../../assets/cogoport_logo.svg'

export const ExploreSidebar = ({
  loading,
  error,
  categories,
  sidebarOpen,
  selectedCategory,
  setSidebarOpen,
  children,
  selectCategory,
  clearCategory,
}) => {
  const renderCategories = () => {
    return loading ? (
      <p className="p-4">Loading...</p>
    ) : error !== null ? (
      <p className="text-red-700 text-md">{error}</p>
    ) : (
      <div>
        {categories
          .filter((c) => c?.status)
          .map((c, idx) => (
            <div
              key={idx}
              className={`flex justify-between items-center px-5 py-2 my-1 rounded-md cursor-pointer hover:bg-sky-100
          ${selectedCategory === c.id ? 'flex-1 bg-sky-100' : 'flex-1'}`}
              onClick={() => selectCategory(c.id)}
            >
              <span
                className={`
              ${
                selectedCategory === c.id
                  ? 'flex-1 bg-sky-100 text-sky-800 font-semibold'
                  : 'flex-1 font-medium'
              }`}
              >
                {c.name}
              </span>
            </div>
          ))}
          <span className='text-xs text-gray-400 px-5'>more coming soon...</span>
      </div>
    )
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className="h-8 w-auto " src={logo} alt="Your Company" />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">{renderCategories()}</nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col border-r">
        <div className="flex flex-grow flex-col overflow-y-auto bg-white pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <img
              className="h-8 w-auto pl-[12px] mt-2"
              src={logo}
              alt="Your Company"
            />
          </div>
          <div className="mt-6 flex flex-1 flex-col ">
            <nav className="flex flex-col justify-between space-y-1 px-2 pb-4 h-full">
              <div>{renderCategories()}</div>
              <div className="p-3 rounded-lg bg-sky-50 text-sky-800">
                <p className="text-sm font-medium mt-3 mb-2">
                  Welcome to Cogo Academy!
                </p>
                <p className="text-sm">
                  Check out our new courses by clicking on any categories above!
                </p>
                <div className="py-10 my-5 rounded-lg bg-sky-100">
                  <img
                    className="h-16 mx-auto w-100"
                    src={logo2}
                    alt="Your Company"
                  />
                </div>
                <div className="flex font-medium">
                  <span className="text-sm mr-3">©onepercentstartups</span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-32">
        <div className="flex flex-col flex-1 md:pl-32">
          <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow md:hidden">
            <button
              type="button"
              className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>

          <main>{children}</main>
        </div>
        <div></div>
      </div>
    </>
  )
}
