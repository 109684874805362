import React from 'react'
import { InstructionsDiv } from '../../theme/html_render'

const EditorInstruction = ({ content }) => {
  return <>
  <h2 className='text-lg font-medium mb-2 border-b'>Instructions</h2>
  <InstructionsDiv dangerouslySetInnerHTML={{ __html: content }}></InstructionsDiv></>
}

export default EditorInstruction
